<template>
  <v-app>
    <dashboard-core-app-bar />
    <!-- <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" /> -->
    <div class="main_box">
      <div class="menu">
        <v-navigation-drawer
          permanent
        >
          <v-list shaped>
            <v-list-item
              to="/admin/dashboard"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-home
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title>首页</v-list-item-title>
            </v-list-item>
            <v-list-group
              prepend-icon="mdi-file-document-edit"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-title>客户数据</v-list-item-title>
              </template>
              <v-list-item to="/admin/list1?formId=ad326bff-dcd0-4610-95c2-3a2d6c4f165a">
                <v-list-item-title link>
                  名片夹
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-th</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/admin/list2?formId=ad326bff-dcd0-4610-95c2-3a2d6c4f165a">
                <v-list-item-title link>
                  客商信息反馈
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-th</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-group
              prepend-icon="mdi-earth"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-title>外商投资信息</v-list-item-title>
              </template>
              <v-list-item to="/admin/list3?formId=bce0f632-42a8-4cf7-a30c-e5c6f00c6e62">
                <v-list-item-title link>
                  项目
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-th</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item to="/admin/list6?formId=bce0f632-42a8-4cf7-a30c-e5c6f00c6e62">
                <v-list-item-title link>
                  海外经贸简讯
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-th</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-item to="/admin/list4?formId=defdcd74-fe79-4906-b3aa-dbdb0ce55afe">
              <v-list-item-icon>
                <v-icon>
                  mdi-book-education
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title>重点推介项目</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/list5?formId=677602b5-ad59-4b33-9f9d-8cba7ae4f363">
              <v-list-item-icon>
                <v-icon>
                  mdi-feature-search
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title>政策库</v-list-item-title>
            </v-list-item>
            <v-list-group
              no-action
              prepend-icon="fa fa-user"
            >
              <template v-slot:activator>
                <v-list-item-title>用户</v-list-item-title>
              </template>
              <v-list-item
                to="/admin/user"
              >
                <v-list-item-title>
                  用户管理
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-user</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                to="/admin/organization"
              >
                <v-list-item-title>
                  组织机构
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-sitemap</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                to="/admin/user-group"
              >
                <v-list-item-title>
                  用户组
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-users</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                to="/admin/role"
              >
                <v-list-item-title>
                  角色
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-user-secret</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item
                to="/admin/register-apply"
              >
                <v-list-item-title>
                  注册申请
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>fa fa-user-circle-o</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div class="main">
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components: {
      DashboardCoreAppBar: () => import('../components/base/AppBar'),
      // DashboardCoreDrawer: () => import('../components/base/Drawer'),
    },
    props: {
      source: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings'],
      ],
      cruds: [
        ['Create', 'add'],
        ['Read', 'insert_drive_file'],
        ['Update', 'update'],
        ['Delete', 'delete'],
      ],
      expandOnHover: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Photos', icon: 'mdi-image' },
        { title: 'About', icon: 'mdi-help-box' },
      ],
      right: null,
      items2: [
        { picture: 28, text: 'Joseph' },
        { picture: 38, text: 'Apple' },
        { picture: 48, text: 'Xbox Ahoy' },
        { picture: 58, text: 'Nokia' },
        { picture: 78, text: 'MKBHD' },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              href: '',
              title: this.$t('my-profile'),
            },
            {
              to: '',
              title: this.$t('edit-profile'),
            },
            {
              to: '',
              title: this.$t('settings'),
            },
          ],
        }
      },
    },
    created () {
      // this.$vuetify.theme.dark = true
    },
  }
// };
</script>
<style scoped>
.menu{
  position: fixed;
  left: 0px;
  z-index: 2;
  padding-top: 64px;
  height: 100vh;
  width: 260px;
}
.main{
  position: absolute;
  left: 260px;
  right: 0px;
  z-index: 2;
  padding-top: 64px;
}
</style>
